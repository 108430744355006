import { last } from 'lodash';
import { DeepPartial } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  AgentWebsiteResponse,
  PropertyFilterResponseBathroomsEnum,
  PropertyFilterResponseBedroomsEnum,
  PropertyFilterResponseCategoryTypeEnum,
  PropertyFilterResponseHomeJunctionPropertyTypesEnum,
} from '../openapi/bff';
import {
  Address,
  AgentBoard,
  BrokerLicense,
  ColorThemeType,
  FilterType,
  LicensedStatesArray,
  RealApiAgentWebsiteResponse,
  Testimony,
} from '../types/real-api-types';
import env from '../utils/env';
import { stateAbbreviationMap } from '../utils/DisplayHelpers';

class BffAgentWebsiteResponseService {
  data: AgentWebsiteResponse;

  constructor(data: AgentWebsiteResponse) {
    this.data = data;
  }

  private getFilterType(): FilterType {
    switch (this.data.propertyFilter?.categoryType) {
      case PropertyFilterResponseCategoryTypeEnum.Rent:
        return 'rent';
      case PropertyFilterResponseCategoryTypeEnum.Mls:
        return 'mls';
      default:
        return 'buy';
    }
  }

  private getMinBeds(): string {
    switch (this.data.propertyFilter?.bedrooms) {
      case PropertyFilterResponseBedroomsEnum.OneOrMore:
        return '1';
      case PropertyFilterResponseBedroomsEnum.TwoOrMore:
        return '2';
      case PropertyFilterResponseBedroomsEnum.ThreeOrMore:
        return '3';
      case PropertyFilterResponseBedroomsEnum.FourOrMore:
        return '4';
      case PropertyFilterResponseBedroomsEnum.FiveOrMore:
        return '5';
      default:
        return '';
    }
  }

  private getMinBaths(): string {
    switch (this.data.propertyFilter?.bathrooms) {
      case PropertyFilterResponseBathroomsEnum.OneOrMore:
        return '1';
      case PropertyFilterResponseBathroomsEnum.TwoOrMore:
        return '2';
      case PropertyFilterResponseBathroomsEnum.ThreeOrMore:
        return '3';
      case PropertyFilterResponseBathroomsEnum.FourOrMore:
        return '4';
      case PropertyFilterResponseBathroomsEnum.FiveOrMore:
        return '5';
      default:
        return '';
    }
  }

  getAgentWebsiteResponse(): RealApiAgentWebsiteResponse {
    const licensed_states_array = this.getLicensedStatesArray();

    const licensed_state_string_formatted = `State: ${licensed_states_array?.[0].state}, License: ${licensed_states_array?.[0].license}`;

    const addresses = this.getAddresses();

    const result: DeepPartial<RealApiAgentWebsiteResponse> = {
      agent: {
        id: this.data.agentResponse?.id as unknown as number,
        licensed_states_array: licensed_states_array,
        licensed_states: this.getLicensedStates(),
        division: this.data.agentResponse?.divisions,
        licensed_state_string_formatted: licensed_state_string_formatted,
        zipcodes: this.getZipCodes(),
        serviceAreaZipcodes: this.data.agentResponse?.serviceAreaZipcodes,
        phone_1_display: this.data.agentResponse?.phoneNumber,
        works_with: this.getWorksWith(),
        profile_image: {
          menu: this.getProfileImage(),
          main: this.getProfileImage(),
          medium: this.getProfileImage(),
        },
        rect_profile_image: this.getProfileImage(),
        cloudinary_image_url: this.getProfileImage(),
        slug: this.data.agentResponse?.slug,
        agent_boards: this.getAgentBoards(),
        about: this.data.agentResponse?.about,
        addresses: addresses,
        state: addresses?.[0]?.state || null,
        name: this.data.agentResponse?.fullName,
        birth_date: this.data.agentResponse?.birthDate,
        board_ids: this.getBoardsIds(),
        zip: this.data.agentResponse?.addresses?.[0]?.zipOrPostalCode || null,
        title: this.data.agentResponse?.title,
        email: this.data.agentResponse?.emailAddress,
        created_at: DateTime.fromMillis(
          (this.data.agentResponse?.createdAt as number) || 0
        ).toISO(),
        updated_at: DateTime.fromMillis(
          (this.data.agentResponse?.updatedAt as number) || 0
        ).toISO(),
        firstname: this.data.agentResponse?.firstName,
        lastname: this.data.agentResponse?.lastName,
        displayName: this.data.agentResponse?.displayName,
        social: {
          facebook_profile_url: this.data.agentResponse?.facebookURL,
          instagram_profile_url: this.data.agentResponse?.instagramURL,
          linkedin_profile_url: this.data.agentResponse?.linkedInURL,
          google_business_profile_url:
            this.data.agentResponse?.googleBusinessProfileURL,
          personal_website_url: this.data.agentResponse?.personalWebsiteURL,
          twitter_profile_url: this.data.agentResponse?.twitterURL,
          yelp_profile_url: this.data.agentResponse?.yelpURL,
          zillow_profile_url: this.data.agentResponse?.zillowURL,
          trulia_profile_url: '',
          internal_website_url: '',
        },
        accountCountry: this.data.agentResponse?.accountCountry,
      },
      broker_licenses: this.getBrokerLicenses(),
      website: {
        show_agent_referral_page: this.data.showReferralLink,
        bottom_title: this.data.bottomTitle,
        bottom_sub_title: this.data.bottomSubtitle,
        main_title: this.data.mainTitle,
        sub_title: this.data.mainSubtitle,
        site_title: this.data.websiteTitle,
        testimonies: this.getTestimonies(),
        color_theme: this.getColorTheme(),
        photo_sets: this.getPhotoSets(),
        filter: {
          type: this.getFilterType(),
          price_max: this.data.propertyFilter?.maxPrice?.toString() || '',
          price_min: this.data.propertyFilter?.minPrice?.toString() || '',
          beds_min: this.getMinBeds(),
          baths_min: this.getMinBaths(),
          mlsnum: this.data.propertyFilter?.mlsNumber || null,
          latitude: this.data.propertyFilter?.latitude?.toString() || '',
          longitude: this.data.propertyFilter?.longitude?.toString() || '',
          google_place_id: this.data.propertyFilter?.googlePlaceId || null,
          is_condo:
            this.data.propertyFilter?.homeJunctionPropertyTypes?.includes(
              PropertyFilterResponseHomeJunctionPropertyTypesEnum.Condominium
            ),
          is_house:
            this.data.propertyFilter?.homeJunctionPropertyTypes?.includes(
              PropertyFilterResponseHomeJunctionPropertyTypesEnum.House
            ),
          is_land:
            this.data.propertyFilter?.homeJunctionPropertyTypes?.includes(
              PropertyFilterResponseHomeJunctionPropertyTypesEnum.Land
            ),
          is_multifamily:
            this.data.propertyFilter?.homeJunctionPropertyTypes?.includes(
              PropertyFilterResponseHomeJunctionPropertyTypesEnum.Multifamily
            ),
          is_townhouse:
            this.data.propertyFilter?.homeJunctionPropertyTypes?.includes(
              PropertyFilterResponseHomeJunctionPropertyTypesEnum.Townhouse
            ),
        },
        title_feature_1: this.data.feature1Title,
        title_feature_2: this.data.feature2Title,
        title_feature_3: this.data.feature3Title,
        subtitle_feature_1: this.data.feature1Content,
        subtitle_feature_2: this.data.feature2Content,
        subtitle_feature_3: this.data.feature3Content,
      },
    };

    return result as RealApiAgentWebsiteResponse;
  }

  getPhotoSets(): string | undefined {
    const photoSet = this.data.photoSet;
    if (photoSet?.startsWith('PHOTO_SET')) {
      const photoNumber = last(photoSet.split('_'));
      return `photoset_${photoNumber}`;
    }
    return photoSet?.toLowerCase();
  }

  getColorTheme(): ColorThemeType {
    return this.data.themeColor?.toLowerCase() as ColorThemeType;
  }

  getAgentBoards(): AgentBoard[] | undefined {
    return this.data.agentResponse?.boards?.map((board) => ({
      id: board.id as unknown as number,
      code: board.code,
      name: board.name,
    }));
  }

  getAddresses(): Address[] | undefined {
    return this.data.agentResponse?.addresses?.map((address) => ({
      address_1: address.streetAddress1,
      address_2: address.streetAddress2,
      city: address.city,
      state: stateAbbreviationMap[address.stateOrProvince!],
      zip_code: address.zipOrPostalCode,
    }));
  }

  getTestimonies(): Testimony[] | undefined {
    return this.data.testimonials?.map((testimony) => ({
      customer_name: testimony.clientName,
      content: testimony.content,
    }));
  }

  getLicensedStatesArray(): LicensedStatesArray[] | undefined {
    return this.data.agentResponse?.licenses?.map((license) => ({
      state: stateAbbreviationMap[license.administrativeArea!.stateOrProvince!],
      license: license.number,
    }));
  }

  getBrokerLicenses(): BrokerLicense[] | undefined {
    return this.data.agentResponse?.offices?.map((office) => ({
      symbol: stateAbbreviationMap[office.administrativeArea!.stateOrProvince!],
      broker_license: office.brokerageLicenseNumber,
      brokerage_license: '',
    }));
  }

  getLicensedStates(): string {
    return (this.data.agentResponse?.licenses || [])
      .map(
        (license) =>
          stateAbbreviationMap[license?.administrativeArea?.stateOrProvince]
      )
      .join(',');
  }

  getZipCodes(): string {
    return (this.data.agentResponse?.addresses || [])
      .map((address) => address?.zipOrPostalCode)
      .join(',');
  }

  getBoardsIds(): number[] | undefined {
    return this.data.agentResponse?.boards?.map(
      (b) => b.id as unknown as number
    );
  }

  getWorksWith(): string[] | undefined {
    return this.data.agentResponse?.competencies?.map(
      (comp) => comp[0] + comp.substring(1).toLowerCase()
    );
  }

  getProfileImage(): string {
    return this.data.agentResponse?.avatar
      ? `${env.NEXT_PUBLIC_YENTA_IMAGE_BASE_URL}/${this.data.agentResponse?.avatar}`
      : '';
  }
}

export default BffAgentWebsiteResponseService;
