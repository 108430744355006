import { DeepPartial } from 'react-hook-form';
import {
  AgentBySlugResponse,
  OfficialPlanAndAwardResponse,
  RevenueShareTierResponse,
} from '../openapi/bff';
import {
  AgentPlanMembershipResponse,
  BFFAgentSlugResponse,
  CommissionPlanResponse,
  EliteEquityAwardPlanResponse,
  EquityAwardPlanResponse,
  EquityPurchasePlanResponse,
  RevenueSharePlanResponse,
  RevenueShareTier,
} from '../types/real-api-types';
import { getMoneyValue } from '../utils/CurrencyUtils';

class BFFAgentSlugResponseService {
  data: AgentBySlugResponse;
  officialPlanAndAwardResponse: OfficialPlanAndAwardResponse;

  constructor(
    data: AgentBySlugResponse,
    officialPlanAndAwardResponse?: OfficialPlanAndAwardResponse
  ) {
    this.data = data;
    this.officialPlanAndAwardResponse = officialPlanAndAwardResponse;
  }

  private getCommissionPlan(): CommissionPlanResponse {
    const commissionPlan = this.officialPlanAndAwardResponse?.commissionPlan;
    return {
      name: commissionPlan?.name || null,
      description: commissionPlan?.description || null,
      country: commissionPlan?.country || null,
      annualCap: getMoneyValue(commissionPlan?.annualCap),
      normalTeamLeadAnnualCap: getMoneyValue(
        commissionPlan?.normalTeamLeadAnnualCap
      ),
      normalTeamMemberAnnualCap: getMoneyValue(
        commissionPlan?.normalTeamMemberAnnualCap,
        1000
      ),
      platinumTeamLeadAnnualCap: getMoneyValue(
        commissionPlan?.platinumTeamLeadAnnualCap
      ),
      platinumTeamMemberAnnualCap: getMoneyValue(
        commissionPlan?.platinumTeamMemberAnnualCap
      ),
      domesticTeamCap: getMoneyValue(commissionPlan?.domesticTeamCap),
      groupTeamCap: getMoneyValue(commissionPlan?.groupTeamCap),
      preCapRealCommissionSplit: commissionPlan?.preCapRealCommissionSplit,
      postCapSalesTransactionFee: getMoneyValue(
        commissionPlan?.postCapSalesTransactionFee
      ),
      postCapLeaseTransactionFee: getMoneyValue(
        commissionPlan?.postCapLeaseTransactionFee
      ),
      postCapTransactionFeePercentage:
        commissionPlan?.postCapTransactionFeePercentage || null,
      minCommissionForPostCapTransactionFeePercentage: getMoneyValue(
        commissionPlan?.minCommissionForPostCapTransactionFeePercentage
      ),
      signUpFee: getMoneyValue(commissionPlan?.signUpFee),
      personalDealFee: getMoneyValue(commissionPlan?.personalDealFee),
      allowedPersonalDeals: commissionPlan?.allowedPersonalDeals,
      annualBrokerageFee: getMoneyValue(commissionPlan?.annualBrokerageFee),
      perTransactionBrokerageFee: getMoneyValue(
        commissionPlan?.perTransactionBrokerageFee
      ),
      minCommissionForBrokerageFee: getMoneyValue(
        commissionPlan?.minCommissionForBrokerageFee
      ),
      commercial: commissionPlan?.commercial || null,
      beopFee: getMoneyValue(commissionPlan?.beopFee),
    };
  }

  private getEquityPurchasePlan(): EquityPurchasePlanResponse {
    const equityPurchasePlan =
      this.officialPlanAndAwardResponse?.equityPurchasePlan;
    return {
      name: equityPurchasePlan?.name || null,
      description: equityPurchasePlan?.description || null,
      country: equityPurchasePlan?.country || null,
      preCapNetCommissionPercent:
        equityPurchasePlan?.preCapNetCommissionPercent || null,
      postCapNetCommissionPercent:
        equityPurchasePlan?.postCapNetCommissionPercent || null,
      preCapBonusSharePercent:
        equityPurchasePlan?.preCapBonusSharePercent || null,
      postCapBonusSharePercent:
        equityPurchasePlan?.postCapBonusSharePercent || null,
      preCapMax: getMoneyValue(equityPurchasePlan?.preCapMax),
      normalTeamMemberPreCapMax: getMoneyValue(
        equityPurchasePlan?.normalTeamMemberPreCapMax
      ),
      normalTeamLeaderPreCapMax: getMoneyValue(
        equityPurchasePlan?.normalTeamLeaderPreCapMax
      ),
      platinumTeamMemberPreCapMax: getMoneyValue(
        equityPurchasePlan?.platinumTeamMemberPreCapMax
      ),
      platinumTeamLeaderPreCapMax: getMoneyValue(
        equityPurchasePlan?.platinumTeamLeaderPreCapMax
      ),
      groupTeamPreCapMax: getMoneyValue(equityPurchasePlan?.groupTeamPreCapMax),
      domesticTeamPreCapMax: getMoneyValue(
        equityPurchasePlan?.domesticTeamPreCapMax
      ),
      postCapMax: getMoneyValue(equityPurchasePlan?.postCapMax),
      normalTeamMemberPostCapMax: getMoneyValue(
        equityPurchasePlan?.normalTeamMemberPostCapMax
      ),
      normalTeamLeaderPostCapMax: getMoneyValue(
        equityPurchasePlan?.normalTeamLeaderPostCapMax
      ),
      platinumTeamMemberPostCapMax: getMoneyValue(
        equityPurchasePlan?.platinumTeamMemberPostCapMax
      ),
      platinumTeamLeaderPostCapMax: getMoneyValue(
        equityPurchasePlan?.platinumTeamLeaderPostCapMax
      ),
      groupTeamPostCapMax: getMoneyValue(
        equityPurchasePlan?.groupTeamPostCapMax
      ),
      domesticTeamPostCapMax: getMoneyValue(
        equityPurchasePlan?.domesticTeamPostCapMax
      ),
      minPurchase: getMoneyValue(equityPurchasePlan?.minPurchase),
    };
  }

  private getEquityAwardPlan(): EquityAwardPlanResponse {
    const equityAwardPlan =
      this.officialPlanAndAwardResponse?.equityPurchasePlan;
    return {
      name: equityAwardPlan?.name || null,
      description: equityAwardPlan?.description || null,
      country: equityAwardPlan?.country || null,
    };
  }

  private getEliteEquityAwardPlan(): EliteEquityAwardPlanResponse {
    const eliteEquityAwardPlan =
      this.officialPlanAndAwardResponse?.eliteEquityAwardPlan;

    return {
      name: eliteEquityAwardPlan?.name || null,
      description: eliteEquityAwardPlan?.description || null,
      country: eliteEquityAwardPlan?.country || null,
      postCapTransactionFeesTotal:
        getMoneyValue(eliteEquityAwardPlan?.postCapTransactionFeesTotal) ||
        null,
      minGCI: getMoneyValue(eliteEquityAwardPlan?.minGCI) || null,
      minTransactionsClosed:
        eliteEquityAwardPlan?.minTransactionsClosed || null,
      minTransactionPrice:
        getMoneyValue(eliteEquityAwardPlan?.minTransactionPrice) || null,
      minParticipationLevel:
        eliteEquityAwardPlan?.minParticipationLevel || null,
      award: getMoneyValue(eliteEquityAwardPlan?.award) || null,
      postEliteLeaseTransactionFee:
        getMoneyValue(eliteEquityAwardPlan?.postEliteLeaseTransactionFee) ||
        null,
      postEliteSalesTransactionFee:
        getMoneyValue(eliteEquityAwardPlan?.postEliteSalesTransactionFee) ||
        null,
    };
  }

  private getRevenueSharePlanTiers(
    tiers: RevenueShareTierResponse[]
  ): RevenueShareTier[] {
    return tiers.map((tier) => ({
      id: tier?.id || null,
      createdAt: tier?.createdAt || null,
      updatedAt: tier?.updatedAt || null,
      tier: tier?.tier || null,
      commissionPercent: tier?.commissionPercent || null,
      numberOfTierOneAgentsToUnlockThisTier:
        tier?.numberOfTierOneAgentsToUnlockThisTier || null,
      maxAnnualAmountPerAgent:
        getMoneyValue(tier?.maxAnnualAmountPerAgent) || null,
    }));
  }

  private getRevenueSharePlan(): RevenueSharePlanResponse {
    const revenueSharePlan = this.officialPlanAndAwardResponse?.revSharePlan;
    return {
      name: revenueSharePlan?.name || null,
      description: revenueSharePlan?.description || null,
      country: revenueSharePlan?.country || null,
      tiers: this.getRevenueSharePlanTiers(revenueSharePlan?.tiers),
      producingSpanInMonths: revenueSharePlan?.producingSpanInMonths || null,
      commissionsForProducingStatus: getMoneyValue(
        revenueSharePlan?.commissionsForProducingStatus
      ),
      annualFee: getMoneyValue(revenueSharePlan?.annualFee),
      processingFeePercent: revenueSharePlan?.processingFeePercent || null,
      willableRevshareAgreementTemplateId:
        revenueSharePlan?.willableRevshareAgreementTemplateId || null,
    };
  }

  private getPlandMembership(): AgentPlanMembershipResponse {
    const commissionPlan = this.getCommissionPlan();
    const equityPurchasePlan = this.getEquityPurchasePlan();
    const equityAwardPlan = this.getEquityAwardPlan();
    const eliteEquityAwardPlan = this.getEliteEquityAwardPlan();
    const revenueSharePlan = this.getRevenueSharePlan();
    const planMembership = this.data.planMembership;

    return {
      id: planMembership?.id || null,
      createdAt: planMembership?.createdAt || null,
      updatedAt: planMembership?.updatedAt || null,
      commissionPlan,
      equityPurchasePlan,
      equityAwardPlan,
      eliteEquityAwardPlan,
      revenueSharePlan,
      status: planMembership?.status || null,
      optInToEquityPlan: planMembership?.optInToEquityPlan || null,
    };
  }

  getBFFAgentSlugResponse(): BFFAgentSlugResponse {
    const agentSlugResponse: DeepPartial<BFFAgentSlugResponse> = {
      firstName: this.data?.firstName || null,
      lastName: this.data?.lastName || null,
      company: this.data?.company || null,
      fullName: this.data?.fullName || null,
      displayName: this.data?.displayName || null,
      avatar: this.data?.avatar || null,
      about: this.data?.about || null,
      title: this.data?.title || null,
      personalWebsiteURL: this.data?.personalWebsiteURL || null,
      facebookURL: this.data?.facebookURL || null,
      twitterURL: this.data?.twitterURL || null,
      instagramURL: this.data?.instagramURL || null,
      youtubeURL: this.data?.youtubeURL || null,
      clubhouseURL: this.data?.clubhouseURL || null,
      linkedInURL: this.data?.linkedInURL || null,
      zillowURL: this.data?.zillowURL || null,
      yelpURL: this.data?.yelpURL || null,
      googleBusinessProfileURL: this.data?.googleBusinessProfileURL || null,
      planMembership: this.getPlandMembership(),
    };

    return agentSlugResponse as BFFAgentSlugResponse;
  }
}

export default BFFAgentSlugResponseService;
