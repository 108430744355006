import {
  AddressRequest,
  AgentBySlugResponse,
  RunwayControllerApi,
} from '../openapi/bff';
import BFFAgentSlugResponseService from '../services/BffAgentSlugResponseService';
import BffAgentWebsiteResponseService from '../services/BffAgentWebsiteResponseService';
import ErrorService from '../services/ErrorService';
import { AddressComponentType } from '../types';
import {
  BFFAgentSlugResponse,
  CoverPhotSet,
  RealApiAgentWebsiteResponse,
  Website,
} from '../types/real-api-types';
import ServerLogger from './ServerLogger';
import { getBFFConfiguration } from './OpenapiConfigurationUtils';

export const AGENT_COVER_IMAGE_SET_BASE_URL =
  'https://bolt-custom-assets.therealbrokerage.com/agent-website/images/themes';

export const COVER_PHOTO_PATH = 'social_card';

export const agentCoverSetMap: Record<string, CoverPhotSet> = {
  cabin: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/country2_2x.jpg`,
  },
  luxury: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/beach1_2x.jpg`,
  },
  urban: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/urban1_2x.jpg`,
  },
  country: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/country1_2x.jpg`,
  },
  photoset_5: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/interiors2_2x.jpg`,
  },
  photoset_6: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/urban2_2x.jpg`,
  },
  photoset_7: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/suburban1_2x.jpg`,
  },
  photoset_8: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/suburban2_2x.jpg`,
  },
  photoset_9: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/beach2_2x.jpg`,
  },
  photoset_10: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/interiors1_2x.jpg`,
  },
  matsimmons: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/matsimmons_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/matsimmons_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/matsimmons_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/matsimmons_2x.jpg`,
  },
};

export const getCoverImageFromSet = (set: string): CoverPhotSet =>
  agentCoverSetMap[set];
export const getPathWithoutQueryString = (path: string): string =>
  path.split('?')[0];

export const arePathsSame = (path1: string, path2: string): boolean =>
  getPathWithoutQueryString(path1) === getPathWithoutQueryString(path2);

export const getAgentWebsiteApiResponse = async (
  agentSlug: string
): Promise<RealApiAgentWebsiteResponse | undefined> => {
  try {
    const { data } = await new RunwayControllerApi(
      getBFFConfiguration()
    ).getAgentWebsiteBySlug(agentSlug);
    if (!data) {
      return null;
    }
    return new BffAgentWebsiteResponseService(data).getAgentWebsiteResponse();
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch agent website using bff',
      e,
      {
        agentWebsite: {
          slug: agentSlug,
        },
      }
    );

    ServerLogger.warn(
      'Could not find agent website using bff, going to try old API for slug=',
      agentSlug
    );
  }

  // This can return null if the slug is not defined.
};

export const checkAvatarExists = (url: string): string | undefined => {
  const reg = /null$/;

  if (reg.test(url)) {
    return undefined;
  }

  return url;
};

const getNameFromAddressComponent = (
  addressComponents: AddressComponentType[],
  level: string
): string => {
  const addressComponentFound = addressComponents.find((addressComponent) =>
    addressComponent.types.includes(level)
  );

  return addressComponentFound?.long_name;
};

export const convertStringToEnums = <D>(txt: string): D =>
  txt.toUpperCase().replace(' ', '_') as unknown as D;

export const getStreet1 = (
  addressComponents: AddressComponentType[]
): string => {
  const address = [];
  const streetNumber = getNameFromAddressComponent(
    addressComponents,
    'street_number'
  );
  if (streetNumber) {
    address.push(streetNumber);
  }

  const route = getNameFromAddressComponent(addressComponents, 'route');
  if (route) {
    address.push(route);
  }

  const locality = getNameFromAddressComponent(addressComponents, 'locality');

  return [address.join(' '), locality].filter((f) => !!f).join(', ');
};

export const getAddressFromAddressComponent = (
  addressComponent: AddressComponentType[]
): AddressRequest => {
  return {
    type: '',
    city: getNameFromAddressComponent(
      addressComponent,
      'administrative_area_level_2'
    ),
    stateOrProvince: convertStringToEnums(
      getNameFromAddressComponent(
        addressComponent,
        'administrative_area_level_1'
      )
    ),
    country: convertStringToEnums(
      getNameFromAddressComponent(addressComponent, 'country')
    ),
    streetAddress1: getStreet1(addressComponent),
    streetAddress2: getNameFromAddressComponent(
      addressComponent,
      'administrative_area_level_3'
    ),
    zipOrPostalCode: getNameFromAddressComponent(
      addressComponent,
      'postal_code'
    ),
  };
};

export const isFeatureDataExist = (data: Website): boolean => {
  if (data.title_feature_1 || data.title_feature_2 || data.title_feature_3) {
    return true;
  } else {
    return false;
  }
};

export const getAgentPlans = async (
  agentSlug: string
): Promise<BFFAgentSlugResponse | null> => {
  try {
    const { data } = await new RunwayControllerApi(
      getBFFConfiguration()
    ).getAgentPlansBySlug(agentSlug);
    const country = data.planMembership?.commissionPlan?.country;
    if (country) {
      const { data: officialPlanByCountry } = await new RunwayControllerApi(
        getBFFConfiguration()
      ).getOfficialPlanByCountry(country);

      return new BFFAgentSlugResponseService(
        data,
        officialPlanByCountry
      ).getBFFAgentSlugResponse();
    }

    return null;
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch agent plans using slug',
      e,
      {
        agent: {
          slug: agentSlug,
        },
      }
    );
  }
};

export const getAgentSlugResponse = async (
  agentSlug: string
): Promise<AgentBySlugResponse | undefined> => {
  try {
    const { data } = await new RunwayControllerApi(
      getBFFConfiguration()
    ).getAgentPlansBySlug(agentSlug);

    return data;
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch agent by slug response',
      e,
      {
        agent: {
          slug: agentSlug,
        },
      }
    );
  }
  return undefined;
};
